import React from 'react';
import { Box } from 'theme-ui';
import Head from 'next/head';
import Layout from '../Layout/Layout';
import { useProspectContext } from '../../../context/ProspectContext';

type Props = {
  children: React.ReactNode;
};

export default function Container(props: Props) {
  const { children } = props;
  const { open, setOpen } = useProspectContext();

  return (
    <Box>
      <Head>
        <title>Flix</title>
      </Head>
      <Layout open={open} setOpen={setOpen}>
        {children}
      </Layout>
    </Box>
  );
}
