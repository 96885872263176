import React from 'react';
import { Box } from 'theme-ui';

type Props = {
  prospectModelOpen: boolean;
};

const BackgroundOverlay = React.memo(function BackgroundOverlay(
  props: Props,
) {
  const { prospectModelOpen } = props;

  return (
    <Box
      sx={{
        transition: '.3s linear',
        top: '0',
        right: '0',
        visibility: !prospectModelOpen ? 'hidden' : 'visible',
        position: 'fixed',
        height: '100vh',
        zIndex: '3',
        width: '100vw',
        background: 'rgba(28, 23, 51, 0.2)',
      }}></Box>
  );
});

export default BackgroundOverlay;
