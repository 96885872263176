import { useEffect } from 'react';

export default function useKeydownEventListenerEscape(
  onKeydown: (event: KeyboardEvent) => void,
) {
  useEffect(() => {
    document.addEventListener('keydown', onKeydown, false);
    return () => {
      document.removeEventListener('keydown', onKeydown, false);
    };
  }, [onKeydown]);
}
