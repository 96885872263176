import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

type Props = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  children: React.ReactNode;
};

export default function Layout(props: Props) {
  const { open, setOpen, children } = props;

  return (
    <>
      <Header setOpen={setOpen} prospectDialogIsOpen={open} />
      <main>{children}</main>
      <Footer />
    </>
  );
}
