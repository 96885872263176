import React, { useCallback, useState } from 'react';
import { Box, Text, Input, Button, Image, Flex, Link } from 'theme-ui';
import { useForm } from 'react-hook-form';
import useKeydownEventListenerEscape from '../../../hooks/useKeydownEventListenerEscape';
import { api } from '../../../services/api';
import LoadingModal from '../../Shared/LoadingModal/LoadingModal';
import { clickEvent, clickEventWithObject } from '../../../utils/gtm';

type Props = {
  open: boolean;
  setOpen: (arg: boolean) => void;
};

type Prospect = {
  name: string | null;
  email: string | null;
};

export default function ProspectModal(props: Props) {
  const { register, handleSubmit, errors } = useForm();
  const { open, setOpen } = props;
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState<boolean>();

  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    },
    [setOpen],
  );

  useKeydownEventListenerEscape(onKeydown);

  const handleDisabledSubmitButton = () => {
    let response = false;
    if (
      !email ||
      !name ||
      !validEmail ||
      !email.includes('@') ||
      !email.includes('.')
    ) {
      response = true;
    }
    return response;
  };

  const onSubmit = (data: Prospect) => {
    const { name, email } = data;
    sessionStorage.setItem(
      'prospect',
      JSON.stringify({
        name,
        email,
      }),
    );
    setIsLoading(true);
    api
      .post('/prospects', {
        name,
        email,
      })
      .then((r) => {
        const event = {
          event: 'prospect_data',
          name: name,
          email: email,
        };
        clickEventWithObject(event);
        if (window.location.host.includes('site.dev')) {
          location.replace(
            `${
              window.location.protocol
            }//checkout.${window.location.host.replace('site.', '')}`,
          );
        } else {
          location.replace(
            `${
              window.location.protocol
            }//checkout.${window.location.host.replace('www.', '')}`,
          );
        }
      })
      .catch(() => {
        setIsLoading(false);
        throw new Error('ProspectModal Error');
      });
  };

  const handleEmailVerify = (value: string) => {
    setEmail(value);
    if (value && value.includes('@') && value.includes('.')) {
      api
        .get(`/users/${value}`)
        .then(() => {
          setValidEmail(false);
        })
        .catch(() => {
          setValidEmail(true);
        });
    }
  };

  return (
    <Box
      sx={{
        zIndex: '4',
        transition: '.6s ease',
        visibility: open ? 'visible' : 'hidden',
        minWidth: '384px',
        position: 'fixed',
        maxWidth: '515px',
        top: open ? '8%' : '0%',
        opacity: open ? '1' : '0',
        marginLeft: '50%',
        transform: 'translate(-50%, 0)',
        backgroundColor: '#fff',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px rgba(44, 44, 82, 0.1), 0px 16px 16px rgba(44, 44, 82, 0.1);',
        '@media screen and (max-width: 768px)': {
          top: '0',
        },
      }}>
      {isLoading ? (
        <LoadingModal />
      ) : (
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          p="2rem 4rem"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Image
            src="./close.svg"
            onClick={() => setOpen(false)}
            title="Fechar"
            sx={{
              width: '10px',
              height: '10px',
              position: 'absolute',
              top: '1.5rem',
              right: '1.5rem',
              '&:hover': { cursor: 'pointer' },
            }}
          />
          <Text
            as="h3"
            sx={{
              '@media screen and (max-width: 500px)': {
                textAlign: 'center',
              },
            }}>
            Descubra seu plano Flix
          </Text>
          <Text
            as="p"
            mb="1.5rem"
            sx={{
              textAlign: 'center',
              fontSize: '1.1rem',
              padding: '0 1rem',
            }}>
            Pra gente conseguir te atender melhor, primeiro preciso que nos
            conte um pouco sobre você e seu imóvel.
          </Text>
          <Input
            name="name"
            ref={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            variant="inputs.default"
            sx={{
              margin: '0.8rem 0',
              width: '408px',
              '@media screen and (max-width: 500px)': { width: '250px' },
            }}
            required
            placeholder="Nome completo*"
            maxLength={100}
            data-cy="input-prospect-name"
          />
          {errors.name && <Text color="#E52D70">Campo obrigatório</Text>}
          <Box sx={{ position: 'relative' }}>
            <Input
              name="email"
              ref={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmailVerify(e?.target?.value)
              }
              variant="inputs.default"
              maxLength={100}
              sx={{
                margin: '0.8rem 0',
                width: '408px',
                '@media screen and (max-width: 500px)': { width: '250px' },
              }}
              required
              type="email"
              placeholder="E-mail*"
              data-cy="input-prospect-email"
            />
            {validEmail !== undefined && !validEmail && (
              <Text
                as="p"
                color="error"
                sx={{ position: 'absolute', bottom: '-0.7rem' }}>
                Vc já é cliente Flix! Faça o Login para adquirir um novo
                plano
              </Text>
            )}
          </Box>
          {validEmail !== undefined && !validEmail && email.length ? (
            <Link
              variant="buttons.primary"
              href="/login"
              onClick={() => clickEvent('clique_btn_prospect_login')}
              mt="1.5rem"
              sx={{
                width: '408px',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@media screen and (max-width: 500px)': { width: '250px' },
              }}>
              Login
            </Link>
          ) : (
            <Button
              type="submit"
              disabled={handleDisabledSubmitButton()}
              onClick={() => clickEvent('clique_btn_popup_cadastro')}
              mt="1.5rem"
              sx={{
                width: '408px',
                ':disabled': {
                  backgroundColor: 'mediumGray',
                  ':hover': { boxShadow: 'none' },
                },
                '@media screen and (max-width: 500px)': { width: '250px' },
              }}
              data-cy="button-start-checkout">
              Pronto, vamos começar!
            </Button>
          )}
          <Text as="p" mt="1rem">
            Não se preocupe, não enviamos SPAM :)
          </Text>
        </Box>
      )}
    </Box>
  );
}
