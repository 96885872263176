import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Flex, Image, Link, Grid, Text } from 'theme-ui';
import NextLink from 'next/link';
import { clickEvent } from '../../../utils/gtm';

type Props = {
  setOpen: (arg: boolean) => void;
  prospectDialogIsOpen?: boolean;
};

export default function Header(props: Props) {
  const { prospectDialogIsOpen } = props;
  const mobileScrollMenuRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > 0 && !stickyHeader) {
      setStickyHeader(true);
    } else if (window.scrollY === 0 && stickyHeader) {
      setStickyHeader(false);
    }
  }, [stickyHeader]);

  const handleOutSideMenuclick = useCallback(
    (event) => {
      if (
        mobileScrollMenuRef.current &&
        event &&
        !mobileScrollMenuRef.current.contains(event.target)
      ) {
        setNavMenuOpen(false);
      }
    },
    [mobileScrollMenuRef],
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    window.addEventListener('mousedown', handleOutSideMenuclick);
    return () => {
      window.removeEventListener('mousedown', handleOutSideMenuclick);
    };
  }, [handleOutSideMenuclick]);

  const headerLinkItem = (ref: string, text: string) => {
    return (
      <Link
        href={ref}
        mr="4"
        onClick={() => {
          clickEvent('clique_menu' + text);
        }}
        sx={{
          fontSize: '14px',
          textDecoration: 'none',
          color: 'black2business',
          textAlign: 'center',
          '&:hover': {
            color: 'primary',
          },
          '@media screen and (max-width: 1366px)': { marginRight: '3' },
        }}>
        {text}
      </Link>
    );
  };

  const headerLinkItemMobile = (ref: string, text: string) => {
    return (
      <Link
        as="li"
        href={ref}
        onClick={() => {
          clickEvent('clique_menu' + text);
        }}
        mr="4"
        sx={{
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          height: '46px',
          transition: '.3s ease',
          paddingLeft: '2rem',
          width: '100%',
          '&:hover': {
            fontWeight: 700,
            color: 'primary',
            backgroundColor: '#F5F5FB',
          },
          '@media screen and (max-width: 1366px)': { marginRight: '3' },
        }}>
        {text}
      </Link>
    );
  };

  const mobileMenu = () => (
    <Box
      ref={mobileScrollMenuRef}
      sx={{
        transition: '1s ease',
        whiteSpace: 'pre',
        overflow: 'hidden',
        position: 'fixed',
        borderRadius: '4px',
        top: '60px',
        left: '1rem',
        width: '248px',
        maxWidth: '248px',
        backgroundColor: '#fff',
        opacity: navMenuOpen ? '1' : '0',
        height: !navMenuOpen ? '0' : '276px',
        maxHeight: !navMenuOpen ? '0' : '276px',
        visibility: navMenuOpen ? 'visible' : 'hidden',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px -8px rgba(44, 44, 82, 0.1), 0px 16px 16px -8px rgba(44, 44, 82, 0.1)',
      }}>
      <Box as="ul">
        {headerLinkItemMobile('/#hero', 'Flix.com.vc')}
        {headerLinkItemMobile('/#emergencial', 'Planos')}
        {headerLinkItemMobile('/#solution', 'Como funciona')}
        {headerLinkItemMobile('/#more-safe', 'Vantagens')}
        {/*{headerLinkItemMobile('/#home-app', 'Baixe o APP')}*/}
      </Box>
    </Box>
  );

  const mobileRightMenu = () => (
    <Box
      ref={mobileScrollMenuRef}
      sx={{
        transition: '.5s ease',
        whiteSpace: 'pre',
        overflow: 'hidden',
        position: 'fixed',
        borderRadius: '4px',
        top: '0',
        right: '0',
        width: isMobileMenuOpen ? '248px' : '0',
        maxWidth: '248px',
        padding: '1.5rem 1rem',
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        backgroundColor: '#fff',
        opacity: isMobileMenuOpen ? '1' : '0',
        visibility: isMobileMenuOpen ? 'visible' : 'hidden',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px -8px rgba(44, 44, 82, 0.1), 0px 16px 16px -8px rgba(44, 44, 82, 0.1)',
      }}>
      <Image
        src="./close.svg"
        width={15}
        onClick={() => setMobileMenuOpen(false)}
        sx={{ position: 'absolute', left: '1rem', top: '1rem' }}
      />
      <NextLink href="/2business">
        <Link
          title="Flix 2Business"
          mb="1.5rem"
          onClick={() => {
            clickEvent('clique_menuFlix2business');
          }}
          sx={{
            fontWeight: 'bold',
            color: 'business',
            transition: '.3s ease',
            letterSpacing: '0.5px',
          }}>
          Flix 2Business
        </Link>
      </NextLink>
      {/*<NextLink href="/perguntas-frequentes">*/}
      {/*  <Link*/}
      {/*    title="Dúvidas? Fale com a Flix"*/}
      {/*    mb="1.5rem"*/}
      {/*    onClick={() => {*/}
      {/*      clickEvent('clique_menuDuvidas?');*/}
      {/*    }}*/}
      {/*    sx={{*/}
      {/*      fontWeight: 'bold',*/}
      {/*      color: 'primary',*/}
      {/*      transition: '.3s ease',*/}
      {/*      letterSpacing: '0.5px',*/}
      {/*    }}>*/}
      {/*    Dúvidas? Fale com a Flix*/}
      {/*  </Link>*/}
      {/*</NextLink>*/}
      <NextLink href="/login">
        <Link
          title="Área do Cliente"
          mb="1.5rem"
          onClick={() => {
            clickEvent('clique_btn_area_do_cliente');
          }}
          sx={{
            letterSpacing: '0.5px',
            fontWeight: 'bold',
            color: 'primary',
            display: 'flex',
            transition: '.3s ease',
          }}>
          Área do Cliente
          <Image ml="2" src="./right_arrow_purple.svg" />
        </Link>
      </NextLink>
      {/*<Button*/}
      {/*  onClick={() => {*/}
      {/*    setOpen(true);*/}
      {/*    clickEvent('clique_menuDescubra');*/}
      {/*  }}*/}
      {/*  title="Descubra o seu plano"*/}
      {/*  variant="buttons.primary"*/}
      {/*  sx={{*/}
      {/*    height: '48px',*/}
      {/*    whiteSpace: 'pre',*/}
      {/*    visibility: isMobileMenuOpen ? 'visible' : 'hidden',*/}
      {/*    opacity: isMobileMenuOpen ? '1' : '0',*/}
      {/*    width: '186px',*/}
      {/*    overflow: 'hidden',*/}
      {/*    transition: '.6s linear',*/}
      {/*  }}>*/}
      {/*  {isMobileMenuOpen && <span>Descubra o seu plano</span>}*/}
      {/*</Button>*/}
    </Box>
  );

  return (
    <Box
      ref={headerRef}
      as="header"
      sx={{
        background: prospectDialogIsOpen ? '#D2CFD6' : 'white',
        position: 'fixed',
        width: 'full',
        transition: '.3s ease',
        zIndex: 2,
        boxShadow: stickyHeader
          ? '0px 4px 8px -2px rgba(38, 37, 98, 0.24)'
          : 'none',
        height: '84px',
        minHeight: '84px',
      }}>
      {mobileMenu()}
      <Grid
        gap="2"
        columns={[3, '3fr 1fr 3fr']}
        sx={{
          '@media screen and (max-width: 1280px)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
          },
        }}>
        <Flex
          sx={{
            alignItems: 'center',
            height: '84px',
            ':first-of-type': { marginLeft: '2rem' },
            '@media screen and (max-width: 1280px)': {
              display: 'none',
            },
          }}>
          {headerLinkItem('/#hero', 'Flix.com.vc')}
          {headerLinkItem('/#emergencial', 'Planos')}
          {headerLinkItem('/#solution', 'Como funciona')}
          {headerLinkItem('/#more-safe', 'Vantagens')}
          {/*{headerLinkItem('/#home-app', 'Baixe o APP')}*/}
        </Flex>
        <Box
          sx={{
            display: 'none',
            alignItems: 'baseline',
            '@media screen and (max-width: 1280px)': { display: 'flex' },
            '@media screen and (max-width: 767px)': { display: 'none' },
          }}>
          <Text
            as="p"
            sx={{ color: 'primary', fontWeight: 'bold', fontSize: '14px' }}
            onClick={() => setNavMenuOpen((state) => !state)}>
            Flix.com.vc
          </Text>
          <Image
            src="./mobileArrow.svg"
            ml="2"
            sx={{
              width: '10px',
              transition: '1s ease',
              transform: 'rotate(-87deg)',
              '@media screen and (max-width: 1280px)': {
                zIndex: '4',
                transform: `${navMenuOpen && 'rotate(360deg)'}`,
              },
            }}
          />
        </Box>
        <NextLink href="/">
          <Image
            src="/flix_logo.svg"
            alt="Flix Logo"
            onClick={() => {
              clickEvent('clique_menu_logo_flix');
            }}
            sx={{
              margin: '0 auto',
              height: '86px',
              width: stickyHeader ? '35%' : '60%',
              transition: '.5s ease',
              '@media screen and (max-width: 1280px)': {
                margin: 'unset',
                width: 'unset',
                height: '43px',
                marginRight: '4rem',
              },
              '&:hover': { cursor: 'pointer' },
            }}
          />
        </NextLink>
        {mobileRightMenu()}
        <Box
          onClick={() => setMobileMenuOpen(true)}
          sx={{
            display: 'none',
            '@media screen and (max-width: 1280px)': {
              display: 'block',
            },
          }}>
          <Image
            src="./burger_icon.svg"
            width={25}
            sx={{
              fill: '#000',
              stroke: '#000',
              color: '#000',
            }}
          />
        </Box>
        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
            transition: '.3s ease',
            '@media screen and (max-width: 1280px)': {
              display: 'none',
            },
          }}>
          <NextLink href="/2business">
            <Link
              title="Flix 2Business"
              onClick={() => {
                clickEvent('clique_menuFlix2business');
              }}
              sx={{
                fontWeight: 'bold',
                color: 'business',
                transition: '.3s ease',
                letterSpacing: '0.5px',
              }}>
              Flix 2Business
            </Link>
          </NextLink>
          {/*<NextLink href="/perguntas-frequentes">*/}
          {/*  <Link*/}
          {/*    title="Dúvidas? Fale com a Flix"*/}
          {/*    onClick={() => {*/}
          {/*      clickEvent('clique_menuDuvidas');*/}
          {/*    }}*/}
          {/*    ml="4"*/}
          {/*    sx={{*/}
          {/*      fontWeight: 'bold',*/}
          {/*      color: 'primary',*/}
          {/*      transition: '.3s ease',*/}
          {/*      letterSpacing: '0.5px',*/}
          {/*    }}>*/}
          {/*    Dúvidas? Fale com a Flix*/}
          {/*  </Link>*/}
          {/*</NextLink>*/}
          <NextLink href="/login">
            <Link
              title="Área do Cliente"
              onClick={() => {
                clickEvent('clique_btn_area_do_cliente');
              }}
              ml="4"
              mr={`${stickyHeader ? '0' : '4'}`}
              sx={{
                letterSpacing: '0.5px',
                fontWeight: 'bold',
                color: 'primary',
                display: 'flex',
                transition: '.3s ease',
              }}>
              Área do Cliente
              <Image
                ml="2"
                src="./right_arrow_purple.svg"
                sx={{
                  '@media (max-width: 1415px)': {
                    marginRight: '0.5rem',
                    marginLeft: '0',
                  },
                }}
              />
            </Link>
          </NextLink>
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    setOpen(true);*/}
          {/*    clickEvent('clique_btn_header_1');*/}
          {/*  }}*/}
          {/*  title="Descubra o seu plano"*/}
          {/*  variant="buttons.primary"*/}
          {/*  ml={`${stickyHeader ? '4' : '0'}`}*/}
          {/*  mr={`${stickyHeader ? '4' : '0'}`}*/}
          {/*  sx={{*/}
          {/*    paddingRight: stickyHeader ? '1rem' : '0',*/}
          {/*    height: '48px',*/}
          {/*    whiteSpace: 'pre',*/}
          {/*    visibility: stickyHeader ? 'visible' : 'hidden',*/}
          {/*    opacity: stickyHeader ? '1' : '0',*/}
          {/*    width: stickyHeader ? '186px' : '0px',*/}
          {/*    overflow: 'hidden',*/}
          {/*    transition: '.6s linear',*/}
          {/*    '@media (max-width: 1415px)': {*/}
          {/*      marginLeft: '0',*/}
          {/*      marginRight: '0',*/}
          {/*    },*/}
          {/*  }}>*/}
          {/*  {stickyHeader && <span>Descubra o seu plano</span>}*/}
          {/*</Button>*/}
        </Flex>
      </Grid>
    </Box>
  );
}
