import * as React from 'react';
import ProspectModal from '../components/Home/ProspectModal/ProspectModal';
import BackgroundOverlay from '../components/Shared/BackgroundOverlay/BackgroundOverlay';

type ProspectContextData = {
  setOpen: (arg0: boolean) => void;
  open: boolean;
};

type ProviderProps = {
  children: React.ReactNode;
};

export const ProspectContext = React.createContext<ProspectContextData>(
  {} as ProspectContextData,
);

export function ProspectProvider({ children }: ProviderProps) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (open) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'scroll';
    }
  }, [open]);

  return (
    <ProspectContext.Provider
      value={{
        setOpen,
        open,
      }}>
      <BackgroundOverlay prospectModelOpen={open} />
      <ProspectModal setOpen={setOpen} open={open} />
      {children}
    </ProspectContext.Provider>
  );
}

export function useProspectContext(): ProspectContextData {
  const context = React.useContext(ProspectContext);

  if (!context) {
    throw new Error('useProspectContext must be within its context');
  }

  return context;
}
