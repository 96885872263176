import React from 'react';
import { Spinner } from 'theme-ui';

type Props = {
  variant?: string;
  size?: string;
  color?: string;
};

export default function LoadingSpinner(props: Props) {
  const { variant, size, color } = props;

  return (
    <>
      <Spinner
        color={color}
        variant={variant ?? 'spinner'}
        size={size ? size : '64'}
      />
    </>
  );
}
