import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export default function LoadingModal() {
  return (
    <Flex
      sx={{
        zIndex: '4',
        transition: '.6s ease',
        position: 'fixed',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '384px',
        height: '204px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px rgba(44, 44, 82, 0.1), 0px 16px 16px rgba(44, 44, 82, 0.1)',
        '@media screen and (max-width: 425px)': { width: '90%' },
      }}>
      <Box mt="4rem">
        <LoadingSpinner />
      </Box>
      <Text
        mt="1rem"
        as="p"
        color="primary"
        sx={{ fontSize: '18px', fontWeight: '800', textAlign: 'center' }}>
        Processando ...
      </Text>
    </Flex>
  );
}
